<template>
  <Toast />
  <div class="col-12">
    <h2
      class="text-center"
      style="
        font-size: 1.5rem;
        font-weight: 700;
        color: #000000;
        margin-bottom: 1rem;
        margin-bottom: 0rem;
      "
    >
      Historia Clínica
    </h2>
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-12">
        <span class="texto-span-2"><strong>A) ANAMNESIS REMOTA:</strong></span
        ><br />
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Antecedentes Familiares:</span>
          <Textarea
            v-model="data_historia_clinica.ant_familiares"
            rows="1"
            autoResize
          />
        </div>
      </div>
      <div class="field col-12 md:col-12">
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Antecedentes M&eacute;dicos:</span>
          <Textarea
            v-model="data_historia_clinica.ant_medicos"
            rows="1"
            autoResize
          />
        </div>
      </div>
      <div class="field col-12 md:col-12">
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Antecedentes Odontol&oacute;gicos:</span>
          <Textarea
            v-model="data_historia_clinica.ant_odontologicos"
            rows="1"
            autoResize
          />
        </div>
      </div>
      <div class="field col-12 md:col-12">
        <span class="texto-span-2"><strong>B) ANAMNESIS PRESENTE:</strong></span
        ><br />
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Motivo de Consulta:</span>
          <Textarea
            v-model="data_historia_clinica.motivo_consulta"
            rows="1"
            autoResize
          />
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Hemorragias:</span>
          <Textarea
            v-model="data_historia_clinica.hemorragias"
            rows="1"
            autoResize
          />
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Alergias:</span>
          <Textarea
            v-model="data_historia_clinica.alergias"
            rows="1"
            autoResize
          />
        </div>
      </div>
      <div class="field col-12 md:col-12">
        <span class="texto-span-2"><strong>C) ESTADO BUCAL:</strong></span>
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Tratamientos Anteriores:</span>
          <Textarea
            v-model="data_historia_clinica.trat_ant"
            rows="1"
            autoResize
          />
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Usa Hilo Dental:</span>
          <Textarea
            v-model="data_historia_clinica.uso_hilo_dental"
            rows="1"
            autoResize
          ></Textarea>
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Frecuencia de Cepillado:</span>
          <Textarea
            v-model="data_historia_clinica.frec_cepillado"
            rows="1"
            autoResize
          />
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span"
            >Sangrado de Enc&iacute;as al Cepillado:</span
          >
          <Textarea
            v-model="data_historia_clinica.sangrado_encias_cepillado"
            rows="1"
            autoResize
          />
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div class="p-inputgroup flex align-items-center">
          <span class="texto-span">Tipo de Cepillo:</span>
          <Textarea
            v-model="data_historia_clinica.tipo_cepillo"
            rows="1"
            autoResize
          />
        </div>
      </div>

      <div class="field col-12 md:col-12">
        <span class="texto-span-2"
          ><strong>D) EXAMEN CL&Iacute;NICO:</strong></span
        ><br />
        <span class="texto-span">1) Evaluaci&oacute;n de Higiene Oral:</span>
      </div>
      <div class="field col-12 md:col-3">
        <Checkbox
          v-model="data_historia_clinica.e_tartaro"
          :binary="true"
          class="mr-1"
        />
        <span class="texto-span">A) T&aacute;rtaro supra o infra gingival</span>
      </div>
      <div class="field col-12 md:col-3">
        <Checkbox
          v-model="data_historia_clinica.e_placa"
          :binary="true"
          class="mr-1"
        />
        <span class="texto-span">B) Placa bacteriana</span>
      </div>
      <div class="field col-12 md:col-3">
        <Checkbox
          v-model="data_historia_clinica.e_saburra"
          :binary="true"
          class="mr-1"
        />
        <span class="texto-span">C) Saburra</span><br />
      </div>
      <div class="field col-12 md:col-3">
        <Checkbox v-model="data_historia_clinica.e_halitosis" :binary="true" />
        <span class="texto-span">D) Halitosis</span>
      </div>
      <div class="field col-12 md:col-12">
        <span class="texto-span"
          >2) Evaluaci&oacute;n de la Oclusi&oacute;n:</span
        >
      </div>
      <div class="field col-12 md:col-3">
        <Checkbox
          v-model="data_historia_clinica.e_llave_moral"
          :binary="true"
          class="mr-1"
        />
        <span class="texto-span">A) Llave Molar</span>
      </div>
      <div class="field col-12 md:col-3">
        <Checkbox
          v-model="data_historia_clinica.e_llave_canina"
          :binary="true"
          class="mr-1"
        />
        <span class="texto-span">B) Llave Canina</span>
      </div>
      <div class="field col-12 md:col-3">
        <Checkbox
          v-model="data_historia_clinica.e_relacion_anterior"
          :binary="true"
          class="mr-1"
        />
        <span class="texto-span">C) Relaci&oacute;n Anterior</span>
      </div>
      <div class="field col-12 md:col-12">
        <span class="texto-span">3) Color de Pieza Dental:</span>
        <Textarea
          v-model="data_historia_clinica.color_pieza_dental"
          rows="1"
          autoResize
        />
      </div>
    </div>
    <div class="p-inputgroup flex justify-content-end">
      <Button
        label="IMPRIMIR"
        icon="pi pi-print"
        class="p-button-lg p-button-warning mr-2"
        @click="imprimirHistoriaClinica()"
        :disabled="this.data_historia_clinica.id ? false : true"
        :loading="imprimiendo"
      />
      <Button
        :label="this.data_historia_clinica.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="this.data_historia_clinica.id ? 'pi pi-refresh' : 'pi pi-save'"
        class="p-button-lg p-button-info"
        @click="saveHistoriaClinica()"
        :loading="cargando"
      />
    </div>
  </div>
</template>
<script>
import PlanTratamientoService from "@/service/PlanTratamientoService";

export default {
  emits: ["actualizarListado"],
  props: {
    datos_historia_clinica: {
      type: Object,
      default: () => {
        return {};
      },
    },
    cliente_id: {
      type: Number,
      default: 0,
    },
  },
  planTratamientoService: null,
  created() {
    this.planTratamientoService = new PlanTratamientoService();
  },
  data() {
    return {
      cargando: false,
      data_historia_clinica: this.datos_historia_clinica,
      data_cliente_id: this.cliente_id,
      imprimiendo: false,
    };
  },
  methods: {
    imprimirHistoriaClinica() {
      this.imprimiendo = true;
      let datos = {
        cliente_id: this.data_cliente_id,
        ant_familiares: this.data_historia_clinica.ant_familiares,
        ant_medicos: this.data_historia_clinica.ant_medicos,
        ant_odontologicos: this.data_historia_clinica.ant_odontologicos,
        motivo_consulta: this.data_historia_clinica.motivo_consulta,
        e_tartaro: this.data_historia_clinica.e_tartaro ? 1 : 0,
        e_placa: this.data_historia_clinica.e_placa ? 1 : 0,
        e_saburra: this.data_historia_clinica.e_saburra ? 1 : 0,
        e_halitosis: this.data_historia_clinica.e_halitosis ? 1 : 0,
        e_llave_moral: this.data_historia_clinica.e_llave_moral ? 1 : 0,
        e_llave_canina: this.data_historia_clinica.e_llave_canina ? 1 : 0,
        e_relacion_anterior: this.data_historia_clinica.e_relacion_anterior
          ? 1
          : 0,
        color_pieza_dental: this.data_historia_clinica.color_pieza_dental,
        hemorragias: this.data_historia_clinica.hemorragias,
        alergias: this.data_historia_clinica.alergias,
        trat_ant: this.data_historia_clinica.trat_ant,
        uso_hilo_dental: this.data_historia_clinica.uso_hilo_dental,
        frec_cepillado: this.data_historia_clinica.frec_cepillado,
        sangrado_encias_cepillado:
          this.data_historia_clinica.sangrado_encias_cepillado,
        tipo_cepillo: this.data_historia_clinica.tipo_cepillo,
      };
      this.planTratamientoService.imprimirHistoriaClinica(datos).then(() => {
        this.imprimiendo = false;
      });
    },
    saveHistoriaClinica() {
      this.cargando = true;
      let datos = {
        cliente_id: this.data_cliente_id,
        ant_familiares: this.data_historia_clinica.ant_familiares,
        ant_medicos: this.data_historia_clinica.ant_medicos,
        ant_odontologicos: this.data_historia_clinica.ant_odontologicos,
        motivo_consulta: this.data_historia_clinica.motivo_consulta,
        e_tartaro: this.data_historia_clinica.e_tartaro ? 1 : 0,
        e_placa: this.data_historia_clinica.e_placa ? 1 : 0,
        e_saburra: this.data_historia_clinica.e_saburra ? 1 : 0,
        e_halitosis: this.data_historia_clinica.e_halitosis ? 1 : 0,
        e_llave_moral: this.data_historia_clinica.e_llave_moral ? 1 : 0,
        e_llave_canina: this.data_historia_clinica.e_llave_canina ? 1 : 0,
        e_relacion_anterior: this.data_historia_clinica.e_relacion_anterior
          ? 1
          : 0,
        color_pieza_dental: this.data_historia_clinica.color_pieza_dental,
        hemorragias: this.data_historia_clinica.hemorragias,
        alergias: this.data_historia_clinica.alergias,
        trat_ant: this.data_historia_clinica.trat_ant,
        uso_hilo_dental: this.data_historia_clinica.uso_hilo_dental,
        frec_cepillado: this.data_historia_clinica.frec_cepillado,
        sangrado_encias_cepillado:
          this.data_historia_clinica.sangrado_encias_cepillado,
        tipo_cepillo: this.data_historia_clinica.tipo_cepillo,
      };
      this.planTratamientoService
        .saveHistoriaClinica(datos)
        .then((response) => {
          if (response.success == false) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.message,
              life: 3000,
            });
            this.cargando = false;
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: response.message,
              life: 3000,
            });
            this.cargando = false;
            this.$emit("actualizarListado");
          }
          this.cargando = false;
        });
    },
  },

  watch: {
    datos_historia_clinica() {
      this.data_historia_clinica = this.datos_historia_clinica;
      //que sea true el check  si es 1
      this.data_historia_clinica.e_tartaro = this.data_historia_clinica
        .e_tartaro
        ? 1 == this.data_historia_clinica.e_tartaro
        : false;
      this.data_historia_clinica.e_placa = this.data_historia_clinica.e_placa
        ? 1 == this.data_historia_clinica.e_placa
        : false;
      this.data_historia_clinica.e_saburra = this.data_historia_clinica
        .e_saburra
        ? 1 == this.data_historia_clinica.e_saburra
        : false;
      this.data_historia_clinica.e_halitosis = this.data_historia_clinica
        .e_halitosis
        ? 1 == this.data_historia_clinica.e_halitosis
        : false;
      this.data_historia_clinica.e_llave_moral = this.data_historia_clinica
        .e_llave_moral
        ? 1 == this.data_historia_clinica.e_llave_moral
        : false;
      this.data_historia_clinica.e_llave_canina = this.data_historia_clinica
        .e_llave_canina
        ? 1 == this.data_historia_clinica.e_llave_canina
        : false;
      this.data_historia_clinica.e_relacion_anterior = this
        .data_historia_clinica.e_relacion_anterior
        ? 1 == this.data_historia_clinica.e_relacion_anterior
        : false;
    },
    cliente_id() {
      this.data_cliente_id = this.cliente_id;
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}
.texto-span {
  color: #000000;
  font-weight: bold;
}
.p-inputtextarea {
  border: none;
  border-bottom: 1px dotted #00346b;
  border-radius: 0;
}
.texto-span-2 {
  color: #00346b;
  font-weight: bold;
  font-size: 1.2rem;
}
</style>
