<template>
  <Toast />
  <ConfirmDialog />
  <ul class="clase-breadcrumb">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Odontologia</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Datos Personales del Paciente</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div class="p-fluid grid style-card">
      <div
        class="field col-12 md:col-1 text-center"
        v-if="clienteSelected.imagen"
      >
        <Image
          :src="'data:image/png;base64, ' + clienteSelected.imagen"
          width="70"
          height="60"
          alt="perfil"
          preview
        >
        </Image>
      </div>

      <div class="field col-12 md:col-4 lg:text-left font-bold text-lg">
        PACIENTE: {{ clienteSelected.nombre ?? "Nombre del paciente" }}
        <span class="text-sm block">
          ID: {{ clienteSelected.id ?? "-" }} | C.I.:
          {{ clienteSelected.ci_nit ?? "-" }} | Edad:
          {{ edadMesAnio(clienteSelected.fecha_nacimiento) }}
        </span>
      </div>
      <div class="field col-12 md:col-2 style-card-alergia">
        <i class="pi pi-exclamation-triangle mr-1 text-red-600"></i>
        <span class="font-bold text-red-600">ALERGIAS:</span>
        <span class="text-lg text-gray-800 block">
          - {{ datos_historia_clinica.alergias ?? "-" }}
        </span>
      </div>
      <div
        :class="
          this.clienteSelected.imagen
            ? 'field col-12 md:col-5'
            : 'field col-12 md:col-6'
        "
      >
        <span class="font-bold"> SELECCIONE UN PACIENTE </span>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-user"></i>
          </span>
          <AutoComplete
            v-model="clienteSelected"
            :suggestions="clientes"
            field="nombre"
            v-tooltip.top="'Buscar paciente'"
            @keyup.enter="buscarCliente"
            @item-select="cargarPlanTratamientos"
          />
          <Button
            :disabled="!clienteSelected"
            icon="pi pi-search"
            class="p-button-warning p-button-sm"
            v-tooltip.top="'Buscar paciente'"
            @click="buscarCliente"
          />
          <Button
            icon="pi pi-user-plus"
            class="p-button-info p-button-sm"
            v-tooltip.top="'Agregar paciente'"
            @click="activarCliente"
          />
        </div>
      </div>
    </div>
    <TabView>
      <TabPanel :disabled="!clienteSelected.id">
        <template #header>
          <div @click="mostrarFormularios = true">
            <i class="pi pi-file"></i>
            <span class="estilo-titulo-tab">Formularios</span>
          </div>
        </template>
        <div v-if="mostrarFormularios">
          <FormConsentimiento
            :cliente_id="clienteSelected.id"
            :datos_historia_clinica="datos_historia_clinica"
            @actualizarListado="cargarPlanTratamientos"
          ></FormConsentimiento>
        </div>
      </TabPanel>
      <TabPanel :disabled="!clienteSelected.id">
        <template #header>
          <div class="custom-tab" @click="activarFormConsentimiento()">
            <i class="pi pi-file mr-1"></i>
            <span class="estilo-titulo-tab">Consentimiento</span>
          </div>
        </template>
        <FormConsentimientoCliente
          :show="formularioConsentimientoDialog"
          :datos_frmprefedinidos="datos_frmprefedinidos"
          :formulario_predefinidos="formulario_predefinidos"
          :cliente_id="clienteSelected.id"
          :medicos="medicos"
          @actualizarListado="cargarPlanTratamientos"
        />
      </TabPanel>
      <TabPanel :disabled="!clienteSelected.id" v-if="false">
        <template #header>
          <i class="pi pi-stop-circle mr-1"></i>
          <span style="color: #002236; cursor: pointer; font-weight: bold"
            >Odontograma</span
          >
        </template>
        <Odontograma
          :show="verOdontograma"
          :medicos="medicos"
          :cliente_id="clienteSelected.id"
          :ubicacion_dental="ubicacion_dental"
        ></Odontograma>
      </TabPanel>
      <TabPanel :disabled="!clienteSelected.id">
        <template #header>
          <div @click="mostrar_imagenes(clienteSelected.id)">
            <i class="pi pi-images mr-1"></i>
            <span class="estilo-titulo-tab">Fotografías/Rayos X</span>
          </div>
        </template>
        <ImagenesPacientes
          :show="verImagenes"
          :cliente_id="ClienteImagenes"
          @closeModal="ocultarDialogImg"
          @actualizarListado="obtenerClientes"
        ></ImagenesPacientes>
      </TabPanel>
      <TabPanel :disabled="!clienteSelected.id">
        <template #header>
          <div @click="cargarPlanTratamientos()">
            <i class="pi pi-list mr-1"></i>
            <span class="estilo-titulo-tab">Trat. Realizados</span>
          </div>
        </template>
        <TratamientosRealizados
          :plan_tratamientos_realiados="plan_tratamientos_realiados"
        ></TratamientosRealizados>
      </TabPanel>
      <TabPanel :disabled="!clienteSelected.id">
        <template #header>
          <div @click="get_datos_receta()">
            <i class="pi pi-file mr-1"></i>
            <span class="estilo-titulo-tab">Recetas</span>
          </div>
        </template>
        <AgregarReceta
          :medicos="medicos"
          :cliente_id="clienteSelected.id"
          :datos_recetas="datos_recetas"
          :datos_recetas_anteriores="this.datos_recetas_anteriores"
          @actualizarListado="cargarPlanTratamientos"
        ></AgregarReceta>
      </TabPanel>
      <TabPanel :disabled="!clienteSelected.id">
        <template #header>
          <div>
            <i class="pi pi-list mr-1"></i>
            <span class="estilo-titulo-tab">Plan de Tratamiento</span>
          </div>
        </template>
        <div class="row flex flex-wrap">
          <div class="field col-12 md:col-6">
            <h4><strong>Plan de Tratamiento</strong></h4>
          </div>
          <div class="field col-12 md:col-3 lg:text-center">
            <span class="text-2xl text-gray-800 font-bold">
              <i class="pi pi-money-bill"></i>
              Total Abonado: </span
            ><br />
            <span class="text-2xl text-green-600 font-bold">
              {{
                clienteSelected.id && monto_abono > 0
                  ? convertirNumeroGermanicFormat(monto_abono)
                  : 0
              }}
              Bs.
            </span>
          </div>
          <div class="field col-12 md:col-3 lg:text-center">
            <span class="text-2xl text-gray-800 font-bold">
              <i class="pi pi-money-bill"></i>
              Saldo Pendiente: </span
            ><br />
            <span
              v-if="!dejo_mas_acuenta"
              class="text-2xl text-red-600 font-bold"
            >
              {{ convertirNumeroGermanicFormat(saldo_pendiente) }} Bs.
            </span>
            <span v-else class="text-2xl text-green-600 font-bold">
              {{ convertirNumeroGermanicFormat(saldo_pendiente) }} Bs.
            </span>
          </div>
          <div
            v-show="false"
            class="field col-12 md:col-3 lg:text-center"
            v-if="pago_clientes"
          >
            <span class="text-2xl text-gray-800 font-bold">
              <i class="pi pi-money-bill"></i>
              Pagar Deudas: </span
            ><br />
            <Button
              v-for="pago in pago_clientes"
              :key="pago.id"
              :label="pago.saldo + ' Bs.'"
              style="
                font-size: 1rem;
                font-weight: bold;
                background-color: red;
                color: #fff;
                border: 1px solid #fff;
                border-radius: 5px;
              "
              @click="pagarDeuda(pago)"
            />
          </div>
        </div>
        <div class="p-fluid grid formgrid style-card2 mb-2">
          <div class="field col-12 md:col-6">
            <span><strong>SELECCIONE MÉDICO:</strong></span>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
              </span>
              <Dropdown
                :disabled="existe_medico_logueado"
                v-model="medicoSelected"
                :options="medicos"
                optionLabel="nombre_completo"
                optionValue="id"
                placeholder="SELECCIONE UN MÉDICO"
                class="p-inputtext-sm mr-2"
                :filter="true"
                v-tooltip.top="'Seleccione un médico'"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6">
            <span><strong>SELECCIONE TRATAMIENTO:</strong></span>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-list"></i>
              </span>
              <AutoComplete
                :disabled="medicoSelected === null"
                v-model="productoSelected"
                :suggestions="productos"
                field="descripcion"
                v-tooltip.top="'Buscar Tratamiento'"
                @keyup.enter="buscarProducto"
                @item-select="agregarPlanTratamiento"
              />
              <Button
                :disabled="productoSelected === null"
                icon="pi pi-search"
                class="p-button-warning p-button-sm"
                v-tooltip.top="'Buscar Tratamiento'"
                @click="buscarProducto"
              />
              <Button
                icon="pi pi-plus"
                class="p-button-info p-button-sm"
                v-tooltip.top="'Agregar nuevo plan de tratamiento'"
                @click="agregarPlanTratamiento"
              />
            </div>
          </div>
        </div>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-12">
            <DataTable
              :value="plan_tratamientos"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              editMode="cell"
              @cell-edit-complete="saveChangeCell"
              :rowhover="true"
              stripedRows
              showGridlines
              selectionMode="single click"
              v-model:expandedRows="expandedRows"
              :loading="cargandoPlanTratamientos"
            >
              <Column expander style="width: 1rem"></Column>
              <Column header="N°" style="width: 2rem">
                <template #body="slotProps">
                  {{ 1 + slotProps.index }}
                </template>
              </Column>
              <Column field="nombre_medico" header="DOCTOR">
                <template #body="slotProps">
                  {{ slotProps.data.nombre_medico ?? "" }}
                </template>
              </Column>
              <Column field="fecha_inicio" header="FECHA" style="width: 7rem">
                <template #body="slotProps">
                  {{ /* formatDate( */ slotProps.data.fecha_inicio /* )  */ }}
                </template>
                <template #editor="{ data, field }">
                  <InputText
                    type="date"
                    format="dd/mm/yyyy"
                    v-model="data[field]"
                    class="p-inputtext-sm"
                    v-tooltip.top="'Seleccione una fecha'"
                  />
                </template>
              </Column>
              <Column
                field="dientes"
                header="N° DIENTE"
                class="bg-blue-200"
                style="font-weight: bold; width: 7rem; text-align: center"
              >
                <template #editor="{ data, field }">
                  <InputText
                    :disabled="
                      data.venta_id !== null && data.venta_id !== undefined
                    "
                    v-model="data[field]"
                    class="p-inputtext-sm"
                  />
                </template>
              </Column>
              <Column field="ubicacion_dental_id" header="UBICACIÓN">
                <template #body="slotProps">
                  {{ slotProps.data.ubicacion_dental?.nombre ?? "" }}
                </template>
                <template #editor="{ data, field }">
                  <Dropdown
                    v-model="data[field]"
                    :options="ubicacion_dental"
                    optionLabel="nombre"
                    optionValue="id"
                    class="p-inputtext-sm"
                    v-tooltip.top="'Seleccione una ubicación dental'"
                  />
                </template>
              </Column>
              <Column
                field="tratamiento"
                header="TRATAMIENTO"
                style="font-weight: bold"
              >
                <template #body="slotProps">
                  {{ slotProps.data.tratamiento }}
                </template>
              </Column>
              <Column
                field="costo"
                header="COSTO"
                style="text-align: right; font-weight: bold"
              >
                <template #body="slotProps">
                  {{ convertirNumeroGermanicFormat(slotProps.data.costo ?? 0) }}
                </template>
                <template #editor="{ data, field }">
                  <InputText
                    :disabled="
                      data.venta_id !== null && data.venta_id !== undefined
                    "
                    v-model="data[field]"
                    class="p-inputtext-sm"
                  />
                </template>
              </Column>
              <Column field="descuento" header="DESC." class="bg-yellow-200">
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(slotProps.data.descuento ?? 0)
                  }}
                </template>
                <template #editor="{ data, field }">
                  <InputText
                    v-model="data[field]"
                    class="p-inputtext-sm"
                    :disabled="
                      data.venta_id !== null && data.venta_id !== undefined
                    "
                  />
                </template>
              </Column>
              <Column
                field="acuenta"
                header="ACUENTA"
                style="text-align: right; font-weight: bold"
              >
                <template #body="slotProps">
                  <span
                    style="font-weight: bold; font-size: 1rem; color: green"
                    >{{
                      convertirNumeroGermanicFormat(slotProps.data.acuenta ?? 0)
                    }}</span
                  >
                </template>
              </Column>
              <Column
                field="saldo"
                header="SALDO"
                style="text-align: right; font-weight: bold"
              >
                <template #body="slotProps">
                  <span v-if="slotProps.data.saldo > 0" style="color: red">{{
                    convertirNumeroGermanicFormat(slotProps.data.saldo)
                  }}</span>

                  <span v-else style="color: green">{{
                    convertirNumeroGermanicFormat(slotProps.data.saldo)
                  }}</span>
                </template>
              </Column>
              <Column field="estado" header="ESTADO">
                <template #body="slotProps">
                  <Badge v-if="slotProps.data.estado === 1" severity="warning"
                    >PENDIENTE</Badge
                  >
                  <Badge v-if="slotProps.data.estado === 2" severity="blue"
                    >PROCESO</Badge
                  >
                  <Badge v-if="slotProps.data.estado === 3" severity="success"
                    >FINALIZADO</Badge
                  >
                  <Badge v-if="slotProps.data.estado === 0" severity="danger"
                    >CANCELADO</Badge
                  >
                </template>
                <template #editor="{ data, field }">
                  <Dropdown
                    v-model="data[field]"
                    :options="estados"
                    optionLabel="label"
                    optionValue="value"
                    class="p-inputtext-sm"
                    v-tooltip.top="'Seleccione un estado'"
                  >
                    <template #option="{ option }">
                      <Badge v-if="option.value === 1" severity="warning"
                        >PENDIENTE</Badge
                      >
                      <Badge v-if="option.value === 2" severity="orange"
                        >PROCESO</Badge
                      >
                      <Badge v-if="option.value === 3" severity="success"
                        >FINALIZADO</Badge
                      >
                      <Badge v-if="option.value === 0" severity="danger"
                        >CANCELADO</Badge
                      >
                    </template>
                  </Dropdown>
                </template>
              </Column>
              <Column field="accion" header="ACCIONES">
                <template #body="slotProps">
                  <div class="flex justify-center">
                    <Button
                      v-if="pago_desde_plan_tratamiento"
                      :disabled="
                        slotProps.data.id == null ||
                        slotProps.data.saldo <= 0 ||
                        slotProps.data.venta_id !== 0
                      "
                      icon="pi pi-money-bill"
                      class="p-button-sm p-button-success mr-1"
                      v-tooltip.top="'Pagar plan de tratamiento'"
                      @click="pagarPlanTratamiento(slotProps.data)"
                    />
                    <Button
                      :disabled="slotProps.data.id == null"
                      icon="pi pi-comments"
                      class="p-button-sm p-button-info mr-1"
                      v-tooltip.top="'Agregar Observación'"
                      @click="activarComentario(slotProps.data.id)"
                    />
                    <Button
                      :disabled="slotProps.data.acuenta > 0"
                      icon="pi pi-trash"
                      class="p-button-sm p-button-danger"
                      v-tooltip.top="'Eliminar plan de tratamiento'"
                      @click="eliminarPlanTratamiento(slotProps.data.id)"
                    />
                  </div>
                </template>
              </Column>
              <template #expansion="slotProps">
                <div style="background-color: #ffffff">
                  <h5>
                    <span style="border-bottom: 1px solid #ccc">
                      DETALLE PLAN DE TRATAMIENTO
                      <strong
                        >{{ slotProps.data.id }} -
                        {{ slotProps.data.tratamiento }}</strong
                      >
                    </span>
                  </h5>
                  <DataTable
                    :value="slotProps.data.detalle_plan_tratamientos"
                    class="p-datatable-sm"
                    responsiveLayout="scroll"
                    stripedRows
                    showGridlines
                    selectionMode="single click"
                    style="
                      border: 1px solid #000000;
                      border-radius: 2px;
                      padding: 2px;
                      box-shadow: 0px 0px 1px 0px #000000;
                      font-size: 12px;
                    "
                  >
                    <Column header="#">
                      <template #body="slotProps">
                        {{ 1 + slotProps.index }}
                      </template>
                    </Column>
                    <Column field="fecha" header="FECHA">
                      <template #body="slotProps">
                        {{ slotProps.data.fecha ?? "" }}
                      </template>
                    </Column>
                    <Column field="nombre_medico" header="MÉDICO">
                      <template #body="slotProps">
                        {{ slotProps.data.nombre_medico ?? "" }}
                      </template>
                    </Column>
                    <Column field="observaciones" header="OBSERVACIONES">
                      <template #body="slotProps">
                        {{ slotProps.data.observaciones ?? "" }}
                      </template>
                    </Column>
                    <Column field="medicamentos" header="MEDICAMENTOS"></Column>
                  </DataTable>
                </div>
              </template>
              <ColumnGroup type="footer">
                <Row>
                  <Column
                    footer="TOTALES:"
                    :colspan="7"
                    footerStyle="text-align:right ; font-weight: bold"
                  />
                  <!--  <Column class="text-right" :footer="total_precio_venta" />
                  <Column class="text-right" :footer="total_descuento" />
                  <Column class="text-right" :footer="total_acuenta" />
                  <Column class="text-right" :footer="total_saldo" /> -->
                </Row>
              </ColumnGroup>
            </DataTable>
          </div>
        </div>
        <div class="row flex flex-wrap">
          <div class="field col-12 md:col-12 lg:text-right">
            <Button
              icon="pi pi-refresh"
              class="p-button-success mr-1 p-button-lg"
              @click="cargarPlanTratamientos()"
              v-tooltip.top="'Recargar datos'"
              :loading="cargandoPlanTratamientos"
            />
            <Button
              label="IMPRIMIR"
              icon="pi pi-print"
              class="p-button-danger mr-1 p-button-lg"
              :disabled="plan_tratamientos.length === 0"
              @click="imprimirPlanTratamiento()"
              :loading="imprimirPlan"
            />
            <Button
              label="PROFORMA"
              icon="pi pi-file-pdf"
              class="p-button-warning mr-1 p-button-lg"
              :disabled="hay_para_proforma"
              @click="imprimirProforma()"
              :loading="generarProforma"
            />
            <Button
              label="GUARDAR"
              icon="pi pi-save"
              class="p-button-info p-button-lg"
              @click="guardarPlanTratamiento()"
              :disabled="plan_tratamientos.length === 0"
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel :disabled="!clienteSelected.id">
        <template #header>
          <div>
            <i class="pi pi-money-bill mr-1"></i>
            <span class="estilo-titulo-tab">Balance</span>
          </div>
        </template>
        <DetalleCarteras :detalle_carteras="detalle_carteras"></DetalleCarteras>
      </TabPanel>
    </TabView>
  </div>
  <ClienteCreate
    :show="clienteDialog"
    :cliente="cliente"
    @actualizarListado="obtenerClientes"
    @closeModal="cerrarClienteDialog"
  />
  <AgregarComentario
    :show="comentarioDialog"
    :comentario="comentario"
    :medicos="medicos"
    @actualizarListado="cargarPlanTratamientos"
    @closeModal="cerrarComentarioDialog"
  />
  <PagoCliente
    :show="pagoClienteDialog"
    :pago_cliente="pago_cliente"
    @closeModal="cerrarModalPagoCliente"
    @actualizarListado="actualizarListado"
  />
  <PagoPlanTratamiento
    :show="pagoPlanTratamientoDialog"
    :pago_plan_tratamiento="pago_plan_tratamiento"
    :medicos="medicos"
    :metodo_pagos="metodo_pagos"
    :permitirPago="permitirPago"
    @closeModal="cerrarModalPagoPlanTratamiento"
    @actualizarListado="actualizarListado"
  />
</template>

<script>
import ImagenesPacientes from "@/module/plantratamientos/ImagenesPacientes.vue";
import PlanTratamientoService from "@/service/PlanTratamientoService";
import ClienteService from "@/service/ClienteService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";
import TratamientosRealizados from "@/module/plantratamientos/TratamientosRealizados.vue";
import MedicoService from "@/service/MedicoService";
import AgregarReceta from "@/module/plantratamientos/AgregarReceta.vue";
import Odontograma from "@/module/plantratamientos/Odontograma.vue";
import FormConsentimiento from "@/module/plantratamientos/FormConsentimiento.vue";
import AgregarComentario from "@/module/plantratamientos/AgregarComentario.vue";
import DetalleCarteras from "@/module/plantratamientos/DetalleCarteras.vue";
import PagoCliente from "@/module/clientes/PagoCliente.vue";
import PagoPlanTratamiento from "@/module/plantratamientos/PagoPlanTratamiento.vue";
import FormConsentimientoCliente from "@/module/plantratamientos/FormConsentimientoCliente.vue";

export default {
  components: {
    ImagenesPacientes,
    ClienteCreate,
    TratamientosRealizados,
    AgregarReceta,
    Odontograma,
    FormConsentimiento,
    AgregarComentario,
    DetalleCarteras,
    PagoCliente,
    PagoPlanTratamiento,
    FormConsentimientoCliente,
  },
  name: "plantratamientos",
  data() {
    return {
      datos_agenda: {},
      verImagenes: false,
      ClienteImagenes: null,
      clienteSelected: "",
      clientes: [],
      clienteDialog: false,
      cliente: { estado: { label: "Activo", value: 1 } },
      plan_tratamientos: [],
      estados: [
        { label: "Pendiente", value: 1 },
        { label: "Proceso", value: 2 },
        { label: "Finalizado", value: 3 },
        { label: "Cancelado", value: 0 },
      ],
      productoSelected: null,
      productos: [],
      medicos: [],
      medicoSelected: null,
      plan_tratamientos_realiados: [],
      verOdontograma: false,
      datos_recetas: [],
      datos_recetas_anteriores: [],
      dejo_mas_acuenta: false,
      ubicacion_dental: [],
      generarProforma: false,
      comentarioDialog: false,
      comentario: {},
      expandedRows: [],
      existe_medico_logueado: false,
      imprimirPlan: false,
      datos_frmprefedinidos: [],
      mostrarFormularios: false,
      cargandoPlanTratamientos: false,
      detalle_carteras: [],
      es_de_agenga: false,
      pago_clientes: [],
      pagoClienteDialog: false,
      pago_cliente: {},
      monto_abono: 0,
      pagoPlanTratamientoDialog: false,
      pago_plan_tratamiento: {},
      metodo_pagos: [],
      alergias: "",
      permitirPago: false,
      pago_desde_plan_tratamiento: false,
      datos_historia_clinica: {},
      formulario_predefinidos: [],
      formularioConsentimientoDialog: false,
    };
  },
  planTratamientoService: null,
  clienteService: null,
  productService: null,
  auth: null,
  medicoService: null,

  created() {
    this.planTratamientoService = new PlanTratamientoService();
    this.clienteService = new ClienteService();
    this.productService = new ProductService();
    this.auth = useAuth();
    this.medicoService = new MedicoService();
  },
  computed: {
    saldo_pendiente() {
      let total = 0;
      this.plan_tratamientos.forEach((plan) => {
        total += parseFloat(plan.saldo);
      });
      return total;
    },
    total_precio_venta() {
      let total = 0;
      this.plan_tratamientos.forEach((plan) => {
        total += parseFloat(plan.costo);
      });
      return this.convertirNumeroGermanicFormat(total);
    },
    total_descuento() {
      let total = 0;
      this.plan_tratamientos.forEach((plan) => {
        total += parseFloat(plan.descuento);
      });
      return this.convertirNumeroGermanicFormat(total);
    },
    total_acuenta() {
      let total = 0;
      this.plan_tratamientos.forEach((plan) => {
        total += parseFloat(plan.acuenta);
      });
      return this.convertirNumeroGermanicFormat(total);
    },
    total_saldo() {
      let total = 0;
      this.plan_tratamientos.forEach((plan) => {
        total += parseFloat(plan.saldo);
      });
      return this.convertirNumeroGermanicFormat(total);
    },
    hay_para_proforma() {
      let si_hay = this.plan_tratamientos.find((plan) => plan.saldo > 0);
      return si_hay ? false : true;
    },
  },
  mounted() {
    this.datos_agenda = this.$route.params;
    if (this.datos_agenda.nombre_cliente) {
      this.clienteSelected = {
        id: parseInt(this.datos_agenda.cliente_id),
        nombre: this.datos_agenda.nombre_cliente,
      };
      this.es_de_agenga = true;
      this.buscarCliente();
    } else {
      this.es_de_agenga = false;
      /* this.cargarMedicos();
      this.cargarPlanTratamientos(); */
    }
  },
  methods: {
    /* formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }, */
    activarFormConsentimiento() {
      this.formularioConsentimientoDialog = true;
    },
    pagarPlanTratamiento(data) {
      this.pagoPlanTratamientoDialog = true;
      this.pago_plan_tratamiento = {
        plan_tratamiento_id: data.id,
        cliente_id: this.clienteSelected.id,
        nombre_cliente: this.clienteSelected.nombre,
        medico_id: this.medicoSelected,
        tratamiento: data.tratamiento,
        ubicacion_dental:
          data.ubicacion_dental !== null ? data.ubicacion_dental.nombre : "",
        costo: data.costo,
        acuenta: data.acuenta,
        saldo: data.saldo,
        monto_abono: this.monto_abono,
      };
    },
    cerrarModalPagoPlanTratamiento() {
      this.pagoPlanTratamientoDialog = false;
    },
    pagarDeuda(pago) {
      this.pagoClienteDialog = true;
      this.pago_cliente = {
        ...pago,
        cliente_nombre: this.clienteSelected.nombre,
        esdeodontologia2: true,
      };
    },
    cerrarModalPagoCliente() {
      this.pagoClienteDialog = false;
    },
    actualizarListado() {
      this.cargarPlanTratamientos();
    },
    activarComentario(id) {
      this.comentarioDialog = true;
      this.comentario = {
        plan_tratamiento_id: id,
        cliente_id: this.clienteSelected.id,
        nombre_cliente: this.clienteSelected.nombre,
        medico_id: this.medicoSelected,
        //MUESTRO LA FECHA Y HORA
        fecha: new Date().toISOString().split("T")[0],
      };
    },
    cerrarComentarioDialog() {
      this.comentarioDialog = false;
    },
    imprimirProforma() {
      this.generarProforma = true;
      let datos = {
        cliente_id: this.clienteSelected.id,
      };
      this.planTratamientoService
        .imprimirProforma(datos)
        .then(() => {
          this.generarProforma = false;
        })
        .catch((error) => console.log(error));
    },
    imprimirPlanTratamiento() {
      this.imprimirPlan = true;
      let datos = {
        cliente_id: this.clienteSelected.id,
      };
      this.planTratamientoService.imprimirPlanTratamiento(datos).then(() => {
        this.imprimirPlan = false;
      });
    },
    get_datos_receta() {
      this.datos_recetas = {
        cliente_id: this.clienteSelected.id,
        nombre_cliente: this.clienteSelected.nombre,
      };
    },
    cargarMedicos() {
      this.medicoService.getMedicosAll().then((data) => {
        this.medicos = data.medicos ?? [];
        //busco el id del medico logueado
        let medico = this.medicos.find(
          (medico) => medico.nombre_completo === this.auth.user.name
        );
        if (medico) {
          this.medicoSelected = medico.id;
          this.existe_medico_logueado = true;
        } else {
          this.existe_medico_logueado = false;
        }
      });
    },
    validarPlanTratamiento() {
      // Recorrer el array de plan_tratamientos y verifica que si dientes está vacío
      let plan = this.plan_tratamientos.find((plan) => plan.dientes === "");
      if (plan) {
        this.$toast.add({
          severity: "error",
          summary: "ERROR",
          detail: "Ingrese el número de pieza dental",
          life: 800,
        });
        return false; // Si encuentra un plan sin dientes, retorna false
      }
      return true; // Si todos los planes tienen dientes, retorna true
    },
    guardarPlanTratamiento() {
      if (!this.validarPlanTratamiento()) return;
      let datos = {
        medico_id: this.medicoSelected,
        /* nombre_medico: this.medicos.find(
          (medico) => medico.id === this.medicoSelected
        ).nombre_completo, */
        cliente_id: this.clienteSelected.id,
        nombre_cliente: this.clienteSelected.nombre,
        plan_tratamientos: this.plan_tratamientos,
      };
      this.planTratamientoService
        .guardarPlanTratamiento(datos)
        .then((response) => {
          if (response.success === false) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail: response.message,
              life: 800,
            });
            return;
          }
          this.$toast.add({
            severity: "success",
            summary: "EXITO",
            detail: response.message,
            life: 800,
          });
          this.plan_tratamientos = [];
          this.productoSelected = null;
          this.cargarPlanTratamientos();
        })
        .catch((error) => console.log(error));
    },
    validaciones() {
      if (!this.medicoSelected) {
        this.$toast.add({
          severity: "error",
          summary: "ERROR",
          detail: "Seleccione un médico",
          life: 800,
        });
        return false;
      }
      if (!this.productoSelected) {
        this.$toast.add({
          severity: "error",
          summary: "ERROR",
          detail: "Seleccione un tratamiento",
          life: 600,
        });
        return false;
      }
      return true;
    },
    cargarPlanTratamientos() {
      //vacio el array de plan_tratamientos
      this.datos_agenda = [];
      this.plan_tratamientos = [];
      this.plan_tratamientos_realiados = [];
      this.datos_recetas_anteriores = [];
      this.detalle_carteras = [];
      this.datos_frmprefedinidos = [];
      this.datos_historia_clinica = {};
      this.ClienteImagenes = this.clienteSelected.id;
      let cliente_id = this.datos_agenda.cliente_id ?? this.clienteSelected.id;
      if (cliente_id !== undefined) {
        let datos = { cliente_id };
        this.cargandoPlanTratamientos = true;
        this.planTratamientoService
          .getPlanTratamientos(datos)
          .then((response) => {
            this.plan_tratamientos = response.plan_tratamientos ?? [];
            this.plan_tratamientos_realiados =
              response.plan_tratamientos_realiados ?? [];
            this.datos_recetas_anteriores = response.datos_recetas ?? [];
            this.ubicacion_dental = response.ubicacion_dental ?? [];
            this.datos_frmprefedinidos = response.frmprefedinidos ?? [];
            this.detalle_carteras = response.detalle_carteras ?? [];

            this.monto_abono = response.monto_abono;

            this.pago_clientes = response.pago_clientes ?? [];
            this.cargandoPlanTratamientos = false;
            this.metodo_pagos = response.metodo_pagos ?? [];
            this.permitirPago = response.permitirPago ?? false;
            this.parametros_sistema = response.parametros_sistema ?? {};
            this.parametros_sistema.some((parametro) => {
              if (parametro.nombre == "pago_desde_plan_tratamiento") {
                this.pago_desde_plan_tratamiento = true;
              }
            });
            this.datos_historia_clinica = response.datos_historia_clinica ?? {};
            this.formulario_predefinidos =
              response.formulario_predefinidos ?? [];
          })
          .catch((error) => console.log(error));
      }
    },
    agregarPlanTratamiento() {
      if (!this.validaciones()) return;
      this.medicoSelected = this.medicos.find(
        (medico) => medico.id === this.medicoSelected
      );

      /* id: this.plan_tratamientos.length + 1, */
      this.plan_tratamientos.push({
        medico_id: this.medicoSelected.id,
        nombre_medico: this.medicoSelected.nombre_completo,
        fecha_inicio: new Date().toLocaleDateString(),
        dientes: "",
        producto_id: this.productoSelected.id,
        tratamiento: this.productoSelected.descripcion,
        costo: this.productoSelected.stock.length
          ? this.productoSelected.stock[0]["precio_venta"]
          : 0,
        descuento: 0,
        acuenta: 0,
        saldo: this.productoSelected.stock.length
          ? this.productoSelected.stock[0]["precio_venta"]
          : 0,
        estado: 1,
        observaciones: "",
        medicamentos: "",
        comision_medico: this.productoSelected.stock.length
          ? this.productoSelected.stock[0]["comision_medico"]
          : 0,
        cubre_seguro: "",
        protesista: 0,
        impuesto: 0,
      });
      this.productoSelected = null;
      this.medicoSelected = this.medicoSelected.id;
    },
    buscarProducto() {
      setTimeout(() => {
        let datos = {
          texto: this.productoSelected,
        };
        this.productService
          .buscar_servicio(datos)
          .then((response) => {
            if (response.status == 400 || response.productos.length == 0) {
              this.$toast.add({
                severity: "error",
                summary: "ERROR",
                detail: "No se encontró el producto",
                life: 300,
              });
              return;
            }
            this.productos = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 200);
    },
    saveChangeCell(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
      if (data[field] !== undefined && data[field] !== null) {
        if (field === "nombre_medico" && this.medicos) {
          const medico = this.medicos.find(
            (medico) => medico.id === data[field]
          );
          data.nombre_medico = medico ? medico.nombre_completo : "";
          data.medico_id = medico.id;
        }

        if (field === "ubicacion_dental_id") {
          const ubicacion = this.ubicacion_dental.find(
            (ubicacion) => ubicacion.id === data[field]
          );
          data.ubicacion_dental = ubicacion ? ubicacion : "";
        }

        if (field === "descuento") {
          if (parseFloat(data.descuento) > parseFloat(data.costo)) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail: "El descuento no puede ser mayor al costo",
              life: 800,
            });
            data.descuento = 0;
            return;
          }
          if (parseFloat(data.acuenta) > 0) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail:
                "No puede modificar el descuento si ya hay un monto a cuenta",
              life: 800,
            });
            return;
          }
          data.saldo =
            parseFloat(data.costo) -
            parseFloat(data.descuento) -
            parseFloat(data.acuenta);
        }

        if (field === "costo") {
          if (data.venta_id !== null && data.venta_id !== undefined) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail:
                "No puede modificar el costo de un tratamiento ya vendido",
              life: 800,
            });
            return;
          }
          if (parseFloat(data.descuento) > parseFloat(data.costo)) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail: "El descuento no puede ser mayor al costo",
              life: 800,
            });
            data.descuento = 0;
            return;
          }
          data.saldo =
            parseFloat(data.costo) -
            parseFloat(data.descuento) -
            parseFloat(data.acuenta);
        }

        if (field === "estado") {
          if (data.saldo > 0 && data[field] === 3) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail: "No puede finalizar un tratamiento con saldo pendiente",
              life: 800,
            });
            data.estado = 2;
            return;
          }
        }

        if (field === "fecha_inicio") {
          // Si es vacío o nulo, asigna la fecha actual
          if (!newValue) {
            data[field] = new Date().toISOString().split("T")[0];
          } else {
            // Verifica que newValue sea una fecha válida antes de asignarla
            const parsedDate = new Date(newValue);
            if (!isNaN(parsedDate.getTime())) {
              data[field] = parsedDate.toISOString().split("T")[0];
            }
          }
        }
      }
    },
    edadMesAnio(fecha) {
      if (!fecha) return "";
      let date = new Date(fecha);
      let hoy = new Date();
      let edad = hoy.getFullYear() - date.getFullYear();
      let m = hoy.getMonth() - date.getMonth();
      if (m < 0 || (m === 0 && hoy.getDate() < date.getDate())) {
        edad--;
        m = 12 + m;
      }
      return `${edad} años ${m} meses`;
    },
    buscarCliente() {
      let datos = {
        texto: this.es_de_agenga
          ? this.datos_agenda.nombre_cliente
          : this.clienteSelected,
        cliente_id: this.clienteSelected.id,
      };
      if (datos.texto === "") {
        this.clientes = [];
        return;
      }
      this.clienteService.buscarXNombre(datos).then((response) => {
        if (response.status == 400 || response.clientes.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el cliente",
            life: 300,
          });
          this.clientes = [];
          return;
        }
        this.clientes = [...response.clientes];
        if (this.es_de_agenga) {
          this.clienteSelected = this.clientes[0];
          this.es_de_agenga = false;
        }
        this.cargarMedicos();
        this.cargarPlanTratamientos();
      });
    },
    activarCliente() {
      this.clienteDialog = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerrarClienteDialog() {
      this.clienteDialog = false;
      this.cargarPlanTratamientos();
    },
    obtenerClientes(selected = null) {
      this.clienteService
        .getClientesAll()
        .then((response) => {
          if (selected) {
            this.clienteSelected = selected;
            this.clientes = [];
          } else {
            this.clientes = response;
          }
          this.cargarMedicos();
          this.cargarPlanTratamientos();
        })
        .catch((error) => console.log(error));
    },
    ocultarDialogImg() {
      this.verImagenes = false;
    },
    mostrar_imagenes(paciente_id) {
      this.ClienteImagenes = parseInt(paciente_id);
      this.verImagenes = true;
    },

    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },

    eliminarPlanTratamiento(id) {
      if (id !== undefined) {
        this.$confirm.require({
          header: "CONFIRMACIÓN",
          message: "¿ESTÁS SEGURO DE ELIMINAR ESTE PLAN DE TRATAMIENTO?",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "ELIMINAR",
          rejectLabel: "CANCELAR",
          acceptClass: "p-button-danger p-button-lg",
          rejectClass: "p-button-success p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          closable: false,
          closeOnEscape: false,
          accept: () => {
            this.planTratamientoService
              .eliminarPlanTratamiento(id)
              .then((response) => {
                if (response.success === false) {
                  this.$toast.add({
                    severity: "error",
                    summary: "ERROR",
                    detail: response.message,
                    life: 800,
                  });
                  return;
                }
                this.$toast.add({
                  severity: "success",
                  summary: "EXITO",
                  detail: response.message,
                  life: 800,
                });
                this.cargarPlanTratamientos();
              })
              .catch((error) => console.log(error));
          },
        });
      } else {
        this.$confirm.require({
          header: "Confirmación",
          icon: "pi pi-exclamation-triangle",
          message: "¿ESTÁS SEGURO DE ELIMINAR ESTE PLAN DE TRATAMIENTO?",
          acceptLabel: "Si, Eliminar",
          rejectLabel: "No, Cancelar",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          accept: () => {
            this.plan_tratamientos.splice(id, 1);
          },
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
//ESTILOS A TABVIEW Y TABPANEL QUIERO LETRAS CON NEGRITA Y COLOR AZUL OSCURO
.p-tabview .p-tabview-nav .p-tabview-nav-link {
  font-weight: bold;
  color: #002236;
}
.estilo-titulo-tab {
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  color: #002236;
}
.style-card {
  background-color: #f0f0f0;
  border: 1px solid #002236;
  border-radius: 5px;
  box-shadow: 0px 0px 1px 0px #002236;
}
.style-card2 {
  background-color: #fcfcfc;
  border: 1px solid #002236;
  border-radius: 10px;
}
.clase-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
}
</style>
