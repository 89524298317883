<template>
  <Toast />
  <TabView>
    <TabPanel :disabled="!data_cliente_id">
      <template #header>
        <div class="custom-tab">
          <i class="pi pi-file mr-1"></i>
          <span class="estilo-titulo-tab">Historia Clínica</span>
        </div>
      </template>
      <FormHistoriaClinica
        :datos_historia_clinica="datos_historia_clinica"
        :cliente_id="data_cliente_id"
        @actualizarListado="obtenerHistoriaClinica"
      />
    </TabPanel>
    <TabPanel :disabled="!data_cliente_id">
      <template #header>
        <div class="custom-tab" @click="obtenerCuestionarioPersonal()">
          <i class="pi pi-list mr-1"></i>
          <span class="estilo-titulo-tab">Cuestionario Personal</span>
        </div>
      </template>
      <CuestionarioPersonal
        :cuestionario_personal="cuestionario_personal"
        :cliente_id="data_cliente_id"
        @actualizarListado="obtenerCuestionarioPersonal"
      ></CuestionarioPersonal>
    </TabPanel>
  </TabView>
</template>
<script>
import PlanTratamientoService from "@/service/PlanTratamientoService";
import FormHistoriaClinica from "@/module/plantratamientos/FormHistoriaClinica.vue";
import CuestionarioPersonal from "@/module/plantratamientos/CuestionarioPersonal.vue";
import { useAuth } from "@/stores";

export default {
  components: {
    FormHistoriaClinica,
    CuestionarioPersonal,
  },
  emits: ["actualizarListado"],
  props: {
    cliente_id: {
      type: Number,
      default: 0,
    },
    datos_historia_clinica: {
      type: Object,
      default: () => ({}),
    },
  },
  planTratamientoService: null,
  auth: null,
  created() {
    this.planTratamientoService = new PlanTratamientoService();
    this.auth = useAuth();
  },

  data() {
    return {
      data_cliente_id: this.cliente_id,
      cuestionario_personal: {},
      guardando: false,
      nombre_cliente: "",
      cargando: false,
      medicoSelected: null,
    };
  },
  methods: {
    obtenerCuestionarioPersonal() {
      this.cargando = true;
      let datos = {
        cliente_id: this.data_cliente_id,
      };
      this.planTratamientoService
        .obtenerCuestionarioPersonal(datos)
        .then((response) => {
          if (response.success == false) {
            this.$toast.add({
              severity: "error",
              summary: "Cuestionario Personal",
              detail: response.message,
              life: 3000,
            });
            this.cuestionario_personal = {};
            this.cargando = false;
          } else {
            this.cuestionario_personal = response.cuestionario_personal || {};
            this.$toast.add({
              severity: "success",
              summary: "Cuestionario Personal",
              detail: response.message,
              life: 3000,
            });
            this.cargando = false;
          }
        });
    },
    obtenerHistoriaClinica() {
      this.$emit("actualizarListado");
    },

    aniosTransform(fecha) {
      let fecha_nacimiento = new Date(fecha);
      let fecha_actual = new Date();
      let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
      let mes = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
      if (
        mes < 0 ||
        (mes === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
      ) {
        edad--;
      }
      return edad;
    },
  },
  watch: {
    cliente_id(val) {
      this.data_cliente_id = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
canvas {
  //que sea mas delgado
  border: 0.5px solid #002236;
}
.estilo-titulo-tab {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #002236;
}
.custom-tab {
  display: flex;
  align-items: center;
  padding: 0px;
  cursor: pointer;
}

.custom-tab:hover {
  background-color: #f0f0f0;
}

.p-tabview-nav {
  background-color: #e0e0e0;
}

.p-tabview-nav .p-tabview-selected {
  background-color: #002236;
  color: #fff;
}

.p-tabview-nav .p-tabview-selected .custom-tab {
  background-color: #002236;
  color: #fff;
}
.descripcion-textarea {
  white-space: pre-wrap;
  font-size: 1.2rem;
  text-align: justify;
}
</style>
